import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';

const drawerWidth = 240;

// Axios-Interceptor hinzufügen, um den Authorization-Header zu jeder Anfrage hinzuzufügen
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#336B87',
      dark: '#115293',
    },
    secondary: {
      main: '#e3f2fd',
    },
  },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#e3f2fd',
  borderRadius: '10px 10px 0 0',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[4],
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
  },
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

export default function DiagnosisList() {
  const [diagnoses, setDiagnoses] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [orderBy, setOrderBy] = useState('diagnosisDate');
  const [order, setOrder] = useState('asc');

  useEffect(() => {
    const fetchDiagnoses = async () => {
      try {
        const response = await axios.get('http://localhost:5000/diagnoses');
        const diagnosesWithTreatments = await Promise.all(
          response.data.map(async (diagnosis) => {
            const treatmentsResponse = await axios.get(`http://localhost:5000/treatments?visitId=${diagnosis.visitId}`);
            return { ...diagnosis, treatments: treatmentsResponse.data };
          })
        );
        setDiagnoses(diagnosesWithTreatments);
      } catch (error) {
        console.error('Failed to fetch diagnoses', error);
      }
    };

    fetchDiagnoses();
  }, []);

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedDiagnoses = diagnoses.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  const searchedDiagnoses = sortedDiagnoses.filter((diagnosis) =>
    diagnosis.diagnosis.toLowerCase().includes(searchFilter.toLowerCase())
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={true}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                marginRight: '36px',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Diagnosenverwaltung
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={true}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 4 }} />
            {secondaryListItems}
            <Divider sx={{ my: 4 }} />
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5 }}>
          <Toolbar />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label="Suche"
                variant="outlined"
                size="small"
                value={searchFilter}
                onChange={handleSearchChange}
                sx={{
                  bgcolor: 'white',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '15px',
                  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '25px',
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: '#2196f3',
                    },
                    '&:hover fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                    '&.Mui-focused fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#1976d2' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'diagnosisDate'}
                      direction={orderBy === 'diagnosisDate' ? order : 'asc'}
                      onClick={() => handleRequestSort('diagnosisDate')}
                    >
                      Diagnosedatum
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'diagnosis'}
                      direction={orderBy === 'diagnosis' ? order : 'asc'}
                      onClick={() => handleRequestSort('diagnosis')}
                    >
                      Diagnose
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>Dokument</StyledTableCell>
                  <StyledTableCell>Behandlungen</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedDiagnoses.map((diagnosis) => (
                  <StyledTableRow key={diagnosis.id}>
                    <TableCell>{dayjs(diagnosis.diagnosisDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{diagnosis.diagnosis}</TableCell>
                    <TableCell>
                      {diagnosis.filePath ? (
                        <a href={`http://localhost:5000/diagnoses/${diagnosis.id}/document`} target="_blank" rel="noopener noreferrer">
                          Dokument anzeigen
                        </a>
                      ) : (
                        'Kein Dokument'
                      )}
                    </TableCell>
                    <TableCell>
                      {diagnosis.treatments.length > 0 ? (
                        <ul>
                          {diagnosis.treatments.map((treatment) => (
                            <li key={treatment.id}>{treatment.treatment}</li>
                          ))}
                        </ul>
                      ) : (
                        'Keine Behandlungen'
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
