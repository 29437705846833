import React, { useState } from 'react';
import axios from 'axios';

function SymptomAnalyzer() {
  const [symptoms, setSymptoms] = useState('');
  const [analysis, setAnalysis] = useState('');

  const handleAnalyze = async () => {
    try {
      const response = await axios.post('http://localhost:5000/Analyze', { symptoms });
      setAnalysis(response.data.analysis);  // Extrahiere den "analysis"-Text
    } catch (error) {
      console.error('Error analyzing symptoms:', error);
      setAnalysis('Failed to analyze symptoms.');
    }
  };

  return (
    <div>
      <textarea
        value={symptoms}
        onChange={(e) => setSymptoms(e.target.value)}
        placeholder="Enter symptoms here..."
      />
      <button onClick={handleAnalyze}>Analyze Symptoms</button>
      <div>
        <h2>Analysis Result:</h2>
        <p>{analysis}</p>  {/* Hier wird der Text korrekt gerendert */}
      </div>
    </div>
  );
}

export default SymptomAnalyzer;
