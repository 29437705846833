import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  Badge,
  Divider,
  List,
  Drawer as MuiDrawer,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  transition: '0.3s',
  boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
  },
}));

function PersonTypesConfig() {
  const [personTypes, setPersonTypes] = useState([]);
  const [addPersonTypeOpen, setAddPersonTypeOpen] = useState(false);
  const [editPersonTypeOpen, setEditPersonTypeOpen] = useState(false);
  const [newPersonType, setNewPersonType] = useState({ name: '', imageUrl: '' });
  const [personTypeToEdit, setPersonTypeToEdit] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchPersonTypes = async () => {
    try {
      const response = await axios.get('http://localhost:5000/PersonTypes');
      setPersonTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch person types', error);
      setSnackbarMessage('Fehler beim Laden der Personentypen.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchPersonTypes();
  }, []);

  const handleAddPersonType = async () => {
    try {
      const response = await axios.post('http://localhost:5000/PersonTypes', newPersonType);
      setPersonTypes([...personTypes, response.data]);
      setAddPersonTypeOpen(false);
      setNewPersonType({ name: '', imageUrl: '' });
      setSnackbarMessage('Personentyp erfolgreich hinzugefügt.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to add person type', error);
      setSnackbarMessage('Fehler beim Hinzufügen des Personentyps.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditPersonType = (personType) => {
    setPersonTypeToEdit(personType);
    setNewPersonType({ name: personType.name, imageUrl: personType.imageUrl });
    setEditPersonTypeOpen(true);
  };

  const handleUpdatePersonType = async () => {
    try {
      await axios.put(`http://localhost:5000/PersonTypes/${personTypeToEdit.id}`, newPersonType);
      setPersonTypes(personTypes.map(pt => (pt.id === personTypeToEdit.id ? { ...pt, ...newPersonType } : pt)));
      setEditPersonTypeOpen(false);
      setPersonTypeToEdit(null);
      setNewPersonType({ name: '', imageUrl: '' });
      setSnackbarMessage('Personentyp erfolgreich aktualisiert.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update person type', error);
      setSnackbarMessage('Fehler beim Aktualisieren des Personentyps.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeletePersonType = async (personTypeId) => {
    try {
      await axios.delete(`http://localhost:5000/PersonTypes/${personTypeId}`);
      setPersonTypes(personTypes.filter(pt => pt.id !== personTypeId));
      setSnackbarMessage('Personentyp erfolgreich gelöscht.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete person type', error);
      setSnackbarMessage('Fehler beim Löschen des Personentyps.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = `/images/${file.name}`;
      setNewPersonType({ ...newPersonType, imageUrl });

      const formData = new FormData();
      formData.append('image', file);

      fetch('http://localhost:5000/upload', {
        method: 'POST',
        body: formData,
      }).then(response => response.json())
        .then(data => {
          console.log('File uploaded successfully:', data);
        })
        .catch(error => {
          console.error('Error uploading file:', error);
        });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Personentypen
      </Typography>
      <Grid container spacing={3}>
        {personTypes.map((pt) => (
          <Grid item key={pt.id} xs={12} sm={6} md={4} lg={3}>
            <StyledCard>
              <CardMedia
                component="img"
                image={pt.imageUrl}
                title={pt.name}
                sx={{ height: 200 }}
              />
              <CardContent>
                <Typography variant="h6">{pt.name}</Typography>
                <IconButton size="small" onClick={() => handleEditPersonType(pt)}>
                  <EditIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
                <IconButton size="small" onClick={() => handleDeletePersonType(pt.id)}>
                  <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledCard onClick={() => setAddPersonTypeOpen(true)}>
            <CardMedia
              component="img"
              image="https://via.placeholder.com/200"
              title="Neuen Personentyp hinzufügen"
              sx={{ height: 200 }}
            />
            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AddCircleOutlineIcon style={{ fontSize: 50, color: 'blue' }} />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Dialog open={addPersonTypeOpen} onClose={() => setAddPersonTypeOpen(false)}>
        <DialogTitle>Neuen Personentyp hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newPersonType.name}
            onChange={(e) => setNewPersonType({ ...newPersonType, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Bild auswählen
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newPersonType.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Ausgewähltes Bild: {newPersonType.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddPersonTypeOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleAddPersonType} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editPersonTypeOpen} onClose={() => setEditPersonTypeOpen(false)}>
        <DialogTitle>Personentyp bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newPersonType.name}
            onChange={(e) => setNewPersonType({ ...newPersonType, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Bild auswählen
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newPersonType.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Ausgewähltes Bild: {newPersonType.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditPersonTypeOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleUpdatePersonType} color="primary">
            Aktualisieren
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

function DepartmentConfig() {
  const [departments, setDepartments] = useState([]);
  const [addDepartmentOpen, setAddDepartmentOpen] = useState(false);
  const [editDepartmentOpen, setEditDepartmentOpen] = useState(false);
  const [newDepartment, setNewDepartment] = useState({ name: '', latitude: '', longitude: '', radius: '', imageUrl: '' });
  const [departmentToEdit, setDepartmentToEdit] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('http://localhost:5000/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Failed to fetch departments', error);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleAddDepartment = async () => {
    try {
      const response = await axios.post('http://localhost:5000/departments', newDepartment);
      setDepartments([...departments, response.data]);
      setAddDepartmentOpen(false);
      setNewDepartment({ name: '', latitude: '', longitude: '', radius: '', imageUrl: '' });
      setSnackbarMessage('Abteilung erfolgreich hinzugefügt.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to add department', error);
      setSnackbarMessage('Fehler beim Hinzufügen der Abteilung.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditDepartment = (department) => {
    setDepartmentToEdit(department);
    setNewDepartment({ name: department.name, latitude: department.latitude, longitude: department.longitude, radius: department.radius, imageUrl: department.imageUrl });
    setEditDepartmentOpen(true);
  };

  const handleUpdateDepartment = async () => {
    try {
      await axios.put(`http://localhost:5000/departments/${departmentToEdit.id}`, newDepartment);
      setDepartments(departments.map(dept => (dept.id === departmentToEdit.id ? { ...dept, ...newDepartment } : dept)));
      setEditDepartmentOpen(false);
      setDepartmentToEdit(null);
      setNewDepartment({ name: '', latitude: '', longitude: '', radius: '', imageUrl: '' });
      setSnackbarMessage('Abteilung erfolgreich aktualisiert.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update department', error);
      setSnackbarMessage('Fehler beim Aktualisieren der Abteilung.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteDepartment = async (departmentId) => {
    try {
      await axios.delete(`http://localhost:5000/departments/${departmentId}`);
      setDepartments(departments.filter(dept => dept.id !== departmentId));
      setSnackbarMessage('Abteilung erfolgreich gelöscht.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete department', error);
      setSnackbarMessage('Fehler beim Löschen der Abteilung.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = `/images/${file.name}`;
      setNewDepartment({ ...newDepartment, imageUrl });

      const formData = new FormData();
      formData.append('image', file);

      fetch('http://localhost:5000/upload', {
        method: 'POST',
        body: formData,
      }).then(response => response.json())
        .then(data => {
          console.log('File uploaded successfully:', data);
        })
        .catch(error => {
          console.error('Error uploading file:', error);
        });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Abteilungen
      </Typography>
      <Grid container spacing={3}>
        {departments.map((dept) => (
          <Grid item key={dept.id} xs={12} sm={6} md={4} lg={3}>
            <StyledCard>
              <CardMedia
                component="img"
                image={dept.imageUrl}
                title={dept.name}
                sx={{ height: 200 }}
              />
              <CardContent>
                <Typography variant="h6">{dept.name}</Typography>
                <Typography variant="body2">Latitude: {dept.latitude}</Typography>
                <Typography variant="body2">Longitude: {dept.longitude}</Typography>
                <Typography variant="body2">Radius: {dept.radius} Meter</Typography>
                <IconButton size="small" onClick={() => handleEditDepartment(dept)}>
                  <EditIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
                <IconButton size="small" onClick={() => handleDeleteDepartment(dept.id)}>
                  <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledCard onClick={() => setAddDepartmentOpen(true)}>
            <CardMedia
              component="img"
              image="https://via.placeholder.com/200"
              title="Neue Abteilung hinzufügen"
              sx={{ height: 200 }}
            />
            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AddCircleOutlineIcon style={{ fontSize: 50, color: 'blue' }} />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Dialog open={addDepartmentOpen} onClose={() => setAddDepartmentOpen(false)}>
        <DialogTitle>Neue Abteilung hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            label="Abteilungsname"
            value={newDepartment.name}
            onChange={(e) => setNewDepartment({ ...newDepartment, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Latitude"
            value={newDepartment.latitude}
            onChange={(e) => setNewDepartment({ ...newDepartment, latitude: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Longitude"
            value={newDepartment.longitude}
            onChange={(e) => setNewDepartment({ ...newDepartment, longitude: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Radius"
            value={newDepartment.radius}
            onChange={(e) => setNewDepartment({ ...newDepartment, radius: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Bild auswählen
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newDepartment.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Ausgewähltes Bild: {newDepartment.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDepartmentOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleAddDepartment} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDepartmentOpen} onClose={() => setEditDepartmentOpen(false)}>
        <DialogTitle>Abteilung bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            label="Abteilungsname"
            value={newDepartment.name}
            onChange={(e) => setNewDepartment({ ...newDepartment, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Latitude"
            value={newDepartment.latitude}
            onChange={(e) => setNewDepartment({ ...newDepartment, latitude: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Longitude"
            value={newDepartment.longitude}
            onChange={(e) => setNewDepartment({ ...newDepartment, longitude: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Radius"
            value={newDepartment.radius}
            onChange={(e) => setNewDepartment({ ...newDepartment, radius: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Bild auswählen
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newDepartment.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Ausgewähltes Bild: {newDepartment.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDepartmentOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleUpdateDepartment} color="primary">
            Aktualisieren
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

function RoleDepartmentConfig() {
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roleDepartments, setRoleDepartments] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);
  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const [newRole, setNewRole] = useState({ name: '' });
  const [users, setUsers] = useState([]);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [newUser, setNewUser] = useState({ username: '', password: '', roleID: '', status: 1 });
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [editRoleOpen, setEditRoleOpen] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchRoles = async () => {
    try {
      const response = await axios.get('http://localhost:5000/userRoles');
      setRoles(response.data);
    } catch (error) {
      console.error('Failed to fetch roles', error);
      setSnackbarMessage('Fehler beim Laden der Rollen.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('http://localhost:5000/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Failed to fetch departments', error);
      setSnackbarMessage('Fehler beim Laden der Abteilungen.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchRoleDepartments = async () => {
    try {
      const response = await axios.get('http://localhost:5000/roleDepartments');
      const roleDeptMap = response.data.reduce((map, rd) => {
        if (!map[rd.roleName]) {
          map[rd.roleName] = new Set();
        }
        map[rd.roleName].add(rd.departmentName);
        return map;
      }, {});
      setRoleDepartments(roleDeptMap);
    } catch (error) {
      console.error('Failed to fetch role departments', error);
      setSnackbarMessage('Fehler beim Laden der Rollenzuordnungen.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('http://localhost:5000/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users', error);
      setSnackbarMessage('Fehler beim Laden der Benutzer.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchDepartments();
    fetchRoleDepartments();
    fetchUsers();
  }, []);

  const handleToggleDepartment = async (roleName, departmentName, isAssigned) => {
    const role = roles.find(r => r.RoleName === roleName);
    const department = departments.find(d => d.name === departmentName);

    try {
      if (isAssigned) {
        await axios.delete('http://localhost:5000/roleDepartments', {
          data: { roleID: role.id, department_id: department.id }
        });
        setRoleDepartments(prev => ({
          ...prev,
          [roleName]: new Set([...prev[roleName]].filter(d => d !== departmentName))
        }));
      } else {
        await axios.post('http://localhost:5000/roleDepartments', {
          roleID: role.id,
          department_id: department.id
        });
        setRoleDepartments(prev => ({
          ...prev,
          [roleName]: new Set(prev[roleName] ? [...prev[roleName], departmentName] : [departmentName])
        }));
      }
      setSnackbarMessage(`Abteilung ${isAssigned ? 'entfernt von' : 'zugeordnet zu'} Rolle ${roleName}.`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to toggle role department', error);
      setSnackbarMessage('Fehler beim Ändern der Rollenzuordnung.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAddUser = async () => {
    if (newUser.username.trim() === '' || newUser.password.trim() === '' || newUser.roleID === '') {
      setSnackbarMessage('Benutzername, Passwort und Rolle dürfen nicht leer sein.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/users', newUser);
      setUsers([...users, response.data]);
      setAddUserOpen(false);
      setNewUser({ username: '', password: '', roleID: '', status: 1 });
      setSnackbarMessage('Benutzer erfolgreich hinzugefügt.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to add user', error);
      setSnackbarMessage('Fehler beim Hinzufügen des Benutzers.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditUser = (user) => {
    setUserToEdit(user);
    setNewUser({ username: user.username, password: '', roleID: user.roleID, status: user.status });
    setEditUserOpen(true);
  };

  const handleUpdateUser = async () => {
    if (newUser.username.trim() === '' || newUser.roleID === '') {
      setSnackbarMessage('Benutzername und Rolle dürfen nicht leer sein.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.put(`http://localhost:5000/users/${userToEdit.id}`, newUser);
      setUsers(users.map(u => (u.id === userToEdit.id ? { ...u, ...newUser } : u)));
      setEditUserOpen(false);
      setUserToEdit(null);
      setNewUser({ username: '', password: '', roleID: '', status: 1 });
      setSnackbarMessage('Benutzer erfolgreich aktualisiert.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update user', error);
      setSnackbarMessage('Fehler beim Aktualisieren des Benutzers.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`http://localhost:5000/users/${userId}`);
      setUsers(users.filter(u => u.id !== userId));
      setSnackbarMessage('Benutzer erfolgreich gelöscht.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete user', error);
      setSnackbarMessage('Fehler beim Löschen des Benutzers.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAddRole = async () => {
    if (newRole.name.trim() === '') {
      setSnackbarMessage('Rollenname darf nicht leer sein.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/userRoles', { RoleName: newRole.name });
      setRoles([...roles, response.data]);
      setAddRoleOpen(false);
      setNewRole({ name: '' });
      setSnackbarMessage('Rolle erfolgreich hinzugefügt.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to add role', error);
      setSnackbarMessage('Fehler beim Hinzufügen der Rolle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditRole = (role) => {
    setRoleToEdit(role);
    setNewRole({ name: role.RoleName });
    setEditRoleOpen(true);
  };

  const handleUpdateRole = async () => {
    if (newRole.name.trim() === '') {
      setSnackbarMessage('Rollenname darf nicht leer sein.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.put(`http://localhost:5000/userRoles/${roleToEdit.id}`, { RoleName: newRole.name });
      setRoles(roles.map(r => (r.id === roleToEdit.id ? { ...r, RoleName: newRole.name } : r)));
      setEditRoleOpen(false);
      setRoleToEdit(null);
      setNewRole({ name: '' });
      setSnackbarMessage('Rolle erfolgreich aktualisiert.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update role', error);
      setSnackbarMessage('Fehler beim Aktualisieren der Rolle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteRole = async (roleId) => {
    try {
      await axios.delete(`http://localhost:5000/userRoles/${roleId}`);
      setRoles(roles.filter(r => r.id !== roleId));
      setSnackbarMessage('Rolle erfolgreich gelöscht.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete role', error);
      setSnackbarMessage('Fehler beim Löschen der Rolle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Abteilungen für Benutzerrollen
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Benutzerrolle</InputLabel>
            <Select
              value={selectedRole || ''}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.RoleName}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" flexGrow={1}>{role.RoleName}</Typography>
                    <IconButton size="small" onClick={() => handleEditRole(role)}>
                      <EditIcon fontSize="small" style={{ color: 'blue' }} />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDeleteRole(role.id)}>
                      <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                    </IconButton>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddRoleOpen(true)}
            sx={{ ml: 2 }}
          >
            Rolle hinzufügen
          </Button>
        </Grid>
        {selectedRole && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Abteilungen für {selectedRole}
            </Typography>
            <Grid container spacing={3}>
              {departments.map((dept) => (
                <Grid item key={dept.id} xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{dept.name}</Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={roleDepartments[selectedRole]?.has(dept.name) || false}
                            onChange={() => handleToggleDepartment(selectedRole, dept.name, roleDepartments[selectedRole]?.has(dept.name))}
                          />
                        }
                        label="Zuordnen"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog open={addRoleOpen} onClose={() => setAddRoleOpen(false)}>
        <DialogTitle>Neue Benutzerrolle hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            label="Rollenname"
            value={newRole.name}
            onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddRoleOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleAddRole} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Benutzerverwaltung
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setAddUserOpen(true)} sx={{ mr: 2 }}>
          Benutzer hinzufügen
        </Button>
        <Grid container spacing={3} mt={2}>
          {users.map((user) => (
            <Grid item key={user.id} xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{user.username}</Typography>
                  <Typography variant="body2">{roles.find(r => r.id === user.roleID)?.RoleName}</Typography>
                  <Typography variant="body2">Status: {user.status === 1 ? 'Aktiv' : 'Inaktiv'}</Typography>
                  <IconButton size="small" onClick={() => handleEditUser(user)}>
                    <EditIcon fontSize="small" style={{ color: 'blue' }} />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Dialog open={addUserOpen} onClose={() => setAddUserOpen(false)}>
          <DialogTitle>Neuen Benutzer hinzufügen</DialogTitle>
          <DialogContent>
            <TextField
              label="Benutzername"
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Passwort"
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              select
              label="Rolle"
              value={newUser.roleID}
              onChange={(e) => setNewUser({ ...newUser, roleID: e.target.value })}
              fullWidth
              margin="normal"
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.RoleName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Status"
              value={newUser.status}
              onChange={(e) => setNewUser({ ...newUser, status: e.target.value })}
              fullWidth
              margin="normal"
            >
              <MenuItem value={1}>Aktiv</MenuItem>
              <MenuItem value={0}>Inaktiv</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddUserOpen(false)} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleAddUser} color="primary">
              Hinzufügen
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={editUserOpen} onClose={() => setEditUserOpen(false)}>
          <DialogTitle>Benutzer bearbeiten</DialogTitle>
          <DialogContent>
            <TextField
              label="Benutzername"
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Passwort"
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              select
              label="Rolle"
              value={newUser.roleID}
              onChange={(e) => setNewUser({ ...newUser, roleID: e.target.value })}
              fullWidth
              margin="normal"
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.RoleName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Status"
              value={newUser.status}
              onChange={(e) => setNewUser({ ...newUser, status: e.target.value })}
              fullWidth
              margin="normal"
            >
              <MenuItem value={1}>Aktiv</MenuItem>
              <MenuItem value={0}>Inaktiv</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditUserOpen(false)} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleUpdateUser} color="primary">
              Aktualisieren
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={addRoleOpen} onClose={() => setAddRoleOpen(false)}>
          <DialogTitle>Neue Benutzerrolle hinzufügen</DialogTitle>
          <DialogContent>
            <TextField
              label="Rollenname"
              value={newRole.name}
              onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddRoleOpen(false)} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleAddRole} color="primary">
              Hinzufügen
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={editRoleOpen} onClose={() => setEditRoleOpen(false)}>
          <DialogTitle>Benutzerrolle bearbeiten</DialogTitle>
          <DialogContent>
            <TextField
              label="Rollenname"
              value={newRole.name}
              onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditRoleOpen(false)} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleUpdateRole} color="primary">
              Aktualisieren
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              Unternehmenskonfiguration
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Abteilungen" />
            <Tab label="Abteilungen für Benutzerrollen" />
            <Tab label="PersonenTypes" />
          </Tabs>
          {tabValue === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DepartmentConfig />
              </Grid>
            </Grid>
          )}
          {tabValue === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RoleDepartmentConfig />
              </Grid>
            </Grid>
          )}
          {tabValue === 2 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PersonTypesConfig />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
