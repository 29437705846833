import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import { mainListItems, secondaryListItems } from './listItems';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#34A836',
    },
    secondary: {
      main: '#e3f2fd',
    },
    background: {
      default: '#f4f6f8',
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  width: '100%',
  maxWidth: '800px',
  margin: 'auto',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(4),
  maxWidth: '100%',
  margin: 'auto',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '& th': {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));


export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [itemsDialogOpen, setItemsDialogOpen] = useState(false);
  const [totalPartClient, setTotalPartClient] = useState(0);
  const [depotDialogOpen, setDepotDialogOpen] = useState(false);
  const [retraitDialogOpen, setRetraitDialogOpen] = useState(false);
  const [montant, setMontant] = useState('');
  const [description, setDescription] = useState('');
  

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get('api/finances');
        const fetchedInvoices = response.data;
  
        console.log('Fetched Invoices:', fetchedInvoices); // Debugging
  
        const totalPartClientSum = fetchedInvoices.reduce((total, invoice) => {
          const invoiceTotal = invoice.items.reduce((sum, item) => {
            // Betrag basierend auf dem Transaktionstyp hinzufügen oder abziehen
            if (invoice.Type === 'Depot') {
              return sum + (item.amount);
            } else if (invoice.Type === 'Retrait') {
              return sum - (item.amount);
            } else {
              return sum + (item.amount * item.part_client / 100); // Standardverhalten
            }
          }, 0);
  
          return total + invoiceTotal;
        }, 0);
  
        console.log('Total Part Client Sum:', totalPartClientSum); // Debugging
  
        setInvoices(fetchedInvoices);
        setTotalPartClient(totalPartClientSum);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };
  
    fetchInvoices(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchInvoices();
    }, 10000); // Set an interval to fetch invoices every 10 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);
  
const navigate = useNavigate(); // Verwende useNavigate zum Weiterleiten

  useEffect(() => {
    // Axios-Interceptor zur automatischen Hinzufügung des Tokens zu jeder Anfrage
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher abrufen
        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Token in den Authorization-Header setzen
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Axios-Interceptor zur Abfangung von abgelaufenen Token
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Erfolgreiche Antwort - kein Handlungsbedarf
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Überprüfen, ob der Fehler auf einen abgelaufenen Token hinweist
          if (error.response.data.error === 'Token abgelaufen') {
            // Token aus dem lokalen Speicher entfernen
            localStorage.removeItem('token');
            // Redirect zur Login-Seite mit useNavigate
            navigate('/login');
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to eject the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]); // Abhängigkeit auf navigate setzen
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleInfoClick = (items) => {
    setSelectedInvoiceItems(items);
    setItemsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setItemsDialogOpen(false);
    setSelectedInvoiceItems([]);
  };

  const handleDepot = () => {
    setDepotDialogOpen(true);
  };

  const handleRetrait = () => {
    setRetraitDialogOpen(true);
  };

  const handleCloseDepotDialog = () => {
    setDepotDialogOpen(false);
    setMontant('');
    setDescription('');
  };

  const handleCloseRetraitDialog = () => {
    setRetraitDialogOpen(false);
    setMontant('');
    setDescription('');
  };

  const handleSubmitDepot = async () => {
    try {
      const items = [
        {
          description: description,
          amount: parseFloat(montant),
          part_client: 0,
          part_assurance: 0
        }
      ];

      await axios.post('api/invoices', {
        patientId: "Depot",
        visitId: "Depot",
        tenantId: "Depot",
        Type: 'Depot',
        items
      });

      alert('Dépôt erfolgreich durchgeführt!');
      handleCloseDepotDialog();

      const response = await axios.get('api/finances');
      setInvoices(response.data);
    } catch (error) {
      console.error('Fehler beim Durchführen des Dépôt:', error);
      alert('Fehler beim Durchführen des Dépôt.');
    }
  };

  const handleSubmitRetrait = async () => {
    try {
      const items = [
        {
          description: description, // Retrait als Beschreibung festlegen
          amount: parseFloat(montant),
          part_client: 0,
          part_assurance: 0
        }
      ];

      await axios.post('api/invoices', {
        patientId: "Retrait",
        visitId: "Retrait",
        tenantId: "Retrait",
        Type: 'Retrait',
        items
      });

      alert('Retrait erfolgreich durchgeführt!');
      handleCloseRetraitDialog();

      const response = await axios.get('api/finances');
      setInvoices(response.data);
    } catch (error) {
      console.error('Fehler beim Durchführen des Retrait:', error);
      alert('Fehler beim Durchführen des Retrait.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarStyled position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{ marginRight: '36px', ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              Gestion de la caisse principale
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBarStyled>
        <DrawerStyled variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </DrawerStyled>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding: 3,
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Caisse principale
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                {totalPartClient.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
              </Typography>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button variant="contained" color="primary" onClick={handleDepot}>
                  Dépôt
                </Button>
                <Button variant="contained" color="secondary" onClick={handleRetrait}>
                  Retrait
                </Button>
              </Box>
            </StyledPaper>

            <StyledTableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Numéro de facture</StyledTableCell>
                    <StyledTableCell>Date de facture</StyledTableCell>
                    <StyledTableCell>Montant</StyledTableCell>
                    <StyledTableCell>Part Client {invoices.length > 0 && `(${invoices[0].items[0].part_client}%)`}</StyledTableCell>
                    <StyledTableCell>Part Assurance {invoices.length > 0 && `(${invoices[0].items[0].part_assurance}%)`}</StyledTableCell>
                    <StyledTableCell>Type de Transaction</StyledTableCell>
                    <StyledTableCell>Info</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {invoices.map((invoice) => {
                   const totalAmount = invoice.items.reduce((sum, item) => {
                    // Add or subtract the amount based on the invoice type
                    if (invoice.Type === 'Depot') {
                      return sum + item.amount;
                    } else if (invoice.Type === 'Retrait') {
                      return sum - item.amount;
                    } else {
                      return sum + item.amount; // default behavior
                    }
                  }, 0);



                    const adjustedTotalAmount_Client = invoice.items.reduce((sum, item) => {
                      return sum + (item.amount * item.part_client / 100);
                    }, 0);


                    
                    const adjustedTotalAmount_assurance = invoice.items.reduce((sum, item) => {
                      return sum + (item.amount * item.part_assurance / 100);


                    }, 0);

                    return (
                      <StyledTableRow key={invoice.id}>
                        <StyledTableCell>{invoice.id}</StyledTableCell>
                        <StyledTableCell>{new Date(invoice.created_at).toLocaleDateString()}</StyledTableCell>
                        <StyledTableCell>{totalAmount.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</StyledTableCell>
                        <StyledTableCell>{adjustedTotalAmount_Client.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</StyledTableCell>
                        <StyledTableCell>{adjustedTotalAmount_assurance.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</StyledTableCell>
                        <StyledTableCell>{invoice.Type}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton onClick={() => handleInfoClick(invoice.items)}>
                            <InfoIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>

            <Dialog open={itemsDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
              <DialogTitle>Information</DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Montant</TableCell>
                      <TableCell>Part Client</TableCell>
                      <TableCell>Part Assurance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedInvoiceItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.amount.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                        <TableCell>{(item.amount * item.part_client / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                        <TableCell>{(item.amount * item.part_assurance / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">Fermer</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={depotDialogOpen} onClose={handleCloseDepotDialog} maxWidth="sm" fullWidth>
              <DialogTitle>Dépôt</DialogTitle>
              <DialogContent>
                <TextField
                  label="Montant"
                  type="number"
                  fullWidth
                  value={montant}
                  onChange={(e) => setMontant(e.target.value)}
                  margin="normal"
                />
                <TextField
                  label="Description"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDepotDialog} color="secondary">Annuler</Button>
                <Button onClick={handleSubmitDepot} color="primary">OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={retraitDialogOpen} onClose={handleCloseRetraitDialog} maxWidth="sm" fullWidth>
              <DialogTitle>Retrait</DialogTitle>
              <DialogContent>
                <TextField
                  label="Montant"
                  type="number"
                  fullWidth
                  value={montant}
                  onChange={(e) => setMontant(e.target.value)}
                  margin="normal"
                />
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRetraitDialog} color="secondary">Abbrechen</Button>
                <Button onClick={handleSubmitRetrait} color="primary">Confirmer</Button>
              </DialogActions>
            </Dialog>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
